import React from "react";
import mlsvd_logo from '../../assets/mlsvd.png'

const Logo = () => {
    return (
        <>
            <img src={mlsvd_logo} alt="Logo" className="w-10 h-10 rounded-full" />
        </>
    );
};

export default Logo;