import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from "./components/Header";
import Home from "./components/pages/Home";
import {GlobalStyle} from "./styles";
import React, { useEffect } from "react";
import Contact from "./components/pages/Contact";
import CookieConsentBlock from "./components/CookieConsentBlock";
import { initializeAnalytics, logPageView } from './analytics';

function App() {
    useEffect(() => {
        initializeAnalytics();
    }, []);

    const Analytics = () => {
        const location = useLocation();
        useEffect(() => {
            logPageView();
        }, [location]);

        return null;
    };
    return (
        <Router>
            <GlobalStyle />
            <Header />
            <Analytics />
            <Routes>
              <Route exact path="/" element={<Home />} />
            {/*  <Route path="/about" component={About} />*/}
            {/*  <Route path="/projects" component={Projects} />*/}
            {/*  <Route path="/blog" component={Blog} />*/}
              <Route path="/contact" element={<Contact />} />
            </Routes>
            {/*<Footer />*/}
            <CookieConsentBlock />
        </Router>
    );
}

export default App;
