import React, { useState, useRef, useEffect } from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Logo from "./partials/Logo";
import { FaBars } from 'react-icons/fa';
import {DiGithubBadge} from "react-icons/di";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [mobileMenuClass, setMobileMenuClass] = useState('');
    const overlayRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        if (!menuOpen) {
            setMobileMenuClass('mobile-menu-open show');
        } else {
            setMobileMenuClass('mobile-menu-open');
        }
    };

    const closeMenu = (e) => {
        if (menuOpen) {
            setMenuOpen(false);
            setMobileMenuClass('mobile-menu-open');
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setMenuOpen(false);
                setMobileMenuClass('');
            }
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [menuOpen]);

    return (
        <Nav className="p-4 flex items-stretch">
            <Link to="/">
                <Logo/>
            </Link>
            <div className="md:hidden flex items-center" onClick={toggleMenu}>
                {!menuOpen && <div className="flex items-center"><FaBars className="text-white mx-2 my-2" size={24}/><MobileMenuLabel>Menu</MobileMenuLabel></div>}
            </div>
            <div ref={overlayRef} onClick={closeMenu}
                 className={`navigation-menu-overlay fixed inset-0 bg-red opacity-50 ${menuOpen ? 'block' : 'hidden'}`}></div>
            <div
                className={`md:flex items-center space-x-4 ${menuOpen ? 'block' : 'hidden'}  md:mr-auto ${mobileMenuClass}`}>

                <NavLink className="md:inline-block" to="/" onClick={closeMenu}>Welcome</NavLink>
                <NavLink className="md:inline-block" style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}} to="https://github.com/mlsvd" onClick={closeMenu}><DiGithubBadge size={24}/><span>My GitHub</span></NavLink>
                {/*<NavLink className="md:inline-block" to="/snippets" onClick={closeMenu}>Snippets</NavLink>*/}
                {/*<NavLink className="md:inline-block" to="/about" onClick={closeMenu}>About</NavLink>*/}
                {/*<NavLink className="md:inline-block" to="/blog">Blog</NavLink>*/}
                {/*<NavLink className="md:inline-block" to="/contact" onClick={closeMenu}>Contact</NavLink>*/}
                <div className="md:hidden" onClick={closeMenu}>
                    {menuOpen &&
                        <button className="bg-gray-500 hover:gray-700 text-white font-bold py-2 px-4 my-4 rounded">
                            Back to page
                        </button>
                    }
                </div>
            </div>
        </Nav>
    );
};

const Nav = styled.nav`
    background: #361f6a;
    padding: 1rem;
    z-index: 10;
`;

const NavLink = styled(Link)`
    color: white;
    margin: 10px 1rem 0 1rem;
    min-width: 90px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const MobileMenuLabel = styled("label")`
    color: white;
    min-width: 90px;
    text-decoration: none;
    position: relative;

    &:hover {
        text-decoration: underline;
    }
`;

export default Header;
