import { createGlobalStyle } from 'styled-components';
//import 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap';

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Poppins', sans-serif;
    //    background-color: black;
    //    color: green;
    //    margin: 0;
    //    padding: 0;
    //    box-sizing: border-box;
    }
    //
    //a {
    //    color: green;
    //    text-decoration: none;
    //
    //    &:hover {
    //        text-decoration: underline;
    //    }
    //}
`;