import React from 'react';
import welcome_cover_image from '../../assets/Sprite_0001.gif'

const WelcomeCover = () => {
    return (
        <div className="welcome-image-container flex flex-col items-center">
            <img className="welcome-image" src={welcome_cover_image} alt="Main" />
        </div>
    );
};

export default WelcomeCover;