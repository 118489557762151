import ReactGA from 'react-ga';

const initializeAnalytics = () => {
    ReactGA.initialize('G-EFHVJRE1EZ');
};

const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
};

export { initializeAnalytics, logPageView };