import React from 'react';

const SmallBadge = ({ badgeText }) => {
    return (
        <span
            // className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 m-1">{badgeText}</span>
            // className="inline-flex items-center rounded-md m-1">{badgeText}</span>
            className="inline-flex items-center rounded-md m-1 bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">{badgeText}</span>
    );
};

export default SmallBadge;