import {CookieConsent} from "react-cookie-consent";
import React from "react";
import cookie_wizard from '../assets/wizard_cookies_consent.gif'

const CookieConsentBlock = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="With a Flick of My Wand, I Consent"
            cookieName="cookie-usage-consent"
            style={{ background: "#505f71",  textAlign: "left" }}
            buttonStyle={{
                backgroundColor: "#F6EF0E",
                color: "#503F77",
                fontSize: "14px",
                fontWeight: "bold",
                fontStyle: "italic",
                borderLeft: "3px solid #CFCF98",
                borderBottom: "3px solid #a3a357",
                borderTop: "1px solid #CFCF98",
                borderRight: "1px solid #a3a357"
            }}
            expires={150}
        >
            <img src={cookie_wizard} alt="Cookie Wizard" className="cookie-consent-quote-image" />
            <p className="cookie-consent-quote-text">Greetings, traveler of the digital realm! Behold, we possess cookies – the arcane artifacts that illuminate our path through the mists of data. </p>
        </CookieConsent>
    );
};

export default CookieConsentBlock;
