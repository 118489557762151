import React from 'react';
import styled from 'styled-components';

const Contact = () => {
    return (
        <ContactContainer className="contact-page-container background-main-dark text-white py-24 sm:py-32">
            <h1>Contact Me</h1>
            <form>
                <Input type="text" placeholder="Name" />
                <Input type="email" placeholder="Email" />
                <TextArea placeholder="Message"></TextArea>
                <Button type="submit">Send</Button>
            </form>
        </ContactContainer>
    );
};

const ContactContainer = styled.div`
  padding: 2rem;
  text-align: center;
`;

const Input = styled.input`
  display: block;
  margin: 1rem auto;
  padding: 0.5rem;
  width: 80%;
`;

const TextArea = styled.textarea`
  display: block;
  margin: 1rem auto;
  padding: 0.5rem;
  width: 80%;
  height: 100px;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background: #333;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background: #555;
  }
`;

export default Contact;